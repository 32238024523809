// 書類や様式のダウンロードページ

<template lang="pug">
el-card.member-documents

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  article(v-if='tree')

    el-tabs(type='card')
      el-tab-pane(
        v-for='tab in tabs',
        :key='tab.id',
        :label='tab.name'
      )
        ul.tab
          li.folder(v-for='folder in tab.children')

            h2 {{ folder.name }}

            ul.files(v-if='folder.children && folder.children.length > 0')
              li.file(v-for='file in folder.children')
                a.filename(:href='urlMap[file.id]', target='_blank', rel='noopener')
                  span
                    i.el-icon-document
                    | {{ file.name }}
                  span {{ file.id | timestampToJpDate }}
            .empty(v-else)
              | ファイルがありません。

</template>

<script>
import get from 'lodash.get'
import getSharedDirectoryTree from '@/api/get-shared-directory-tree'

export default {
  name: 'MemberDocuments',

  data() {
    return {
      tree: null,
      urlMap: {},
    }
  },

  computed: {
    tabs() {
      return get(this.tree, 'children', [])
    },
  },

  async created() {
    const response = await getSharedDirectoryTree()
    if (!response.ok) {
      return
    }
    this.tree = response.payload.tree
    this.urlMap = Object.assign({}, this.urlMap, response.payload.urlMap)
  },
}
</script>
<style lang="sass">
.member-documents
  .el-tabs__item
    font-size: 1rem
    &.is-active
      font-weight: bold
      text-decoration: underline
</style>

<style lang="sass" scoped>
.member-documents

  ul
    list-style-type: none

  ul.tab

    li.folder
      padding: 2rem 1rem
      &:nth-child(n+2)
        border-top: 1px solid $color-1
      h2
        font-size: 1.1rem
        color: $grey-dark
        background-image: url('../../assets/folder-icon.png')
        background-repeat: no-repeat
        background-position: center left
        background-size: 1.5rem
        padding-left: 2rem

      ul.files
        margin-top: 1rem
        // background: #fffbdc
        border-radius: 5px
        padding: 0 1rem
        li
          border-top: 2px solid $white-ter
          overflow: hidden
          &:last-child
            border-bottom: 2px solid $white-ter
          a
            display: block
            padding: 0.5rem
            text-decoration: none
            background: #feffe9
            &:hover
              background: lighten($color-1, 45%)

            i
              margin-right: 0.5rem
            span:last-child
              float: right
              color: darken($color-1, 20%)
      .empty
        margin-top: 0.5rem
        color: $grey
        font-size: 0.9rem
</style>
